import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import AbheePride from "../../../components/developers/abheedeveloper/ourproperties/AbheePride";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const AbheePridePage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <AbheePride />
    </>
  )
}

export default AbheePridePage
